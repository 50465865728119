import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as React from "react";
import axios from "axios";

const Question: React.FC = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);
  const [dragging, setDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [option1, setOption1] = useState<string>(
    "skipped due to services page"
  );
  const [option2, setOption2] = useState<string>("");
  const [option3, setOption3] = useState<string>("");
  const [concernQuestion, setConcernQuestion] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [personalInfomation, setPersonalInfomation] = useState<object>({});

  const currentUrl = window.location.href;

  const initialQuestions = [
    {
      question: t("servicesQuestion"),
      options: [
        t("newRoofing"),
        t("roofRepair"),
        t("roofMaintenance"),
        t("roofInspection"),
        t("other"),
      ],
      type: "selection",
      value: option1,
      function: setOption1,
    },
    {
      question: t("propertyTypeQuestion"),
      options: [
        t("singleFamily"),
        t("multiFamily"),
        t("commercialProperty"),
        t("propertyOther"),
      ],
      type: "selection",
      value: option2,
      function: setOption2,
    },
    {
      question: t("currentRoofTypeQuestion"),
      options: [
        t("roofTiles"),
        t("bitumen"),
        t("epdm"),
        t("greenRoof"),
        t("roofTypeOther"),
      ],
      type: "selection",
      value: option3,
      function: setOption3,
    },
    {
      question: t("photosQuestion"),
      type: "image",
    },
    {
      question: t("personalInfo"),
      type: "form",
    },
    // Add more questions and options as needed
  ];

  const [questions, setQuestions] = useState(initialQuestions);

  React.useEffect(() => {
    let updatedQuestions = [...initialQuestions];

    if (currentUrl.includes("diensten")) {
      updatedQuestions = updatedQuestions.slice(1);
    }

    setQuestions(updatedQuestions);
  }, [currentUrl]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  const handleInputChange = (event) => {
    const selectedFiles = [...event.target.files];
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handlePlaceholderClick = () => {
    document.getElementById("file-input").click();
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleOptionChange = (option: string) => {
    questions[step].function(option);
    setStep(step + 1);
  };

  const handleInfoSubmit = (e) => {
    e.preventDefault();
    const personaInformationObject = {
      name: name,
      email: email,
      contact: contact,
    };

    setPersonalInfomation(personaInformationObject);
    setStep(step + 1);
  }

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("service", option1);
    formData.append("current_type", option2);
    formData.append("property_type", option3);
    formData.append("concern_question", concernQuestion);
    formData.append(
      "personal_information",
      JSON.stringify(personalInfomation)
    );
    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });
    axios
      .post("/form/create", formData)
      .then((response) => {
        const formArea = document.getElementById("question-form");
        formArea.innerHTML = t("formSubmitMessage");
        formArea.style.textAlign = "center";
        formArea.style.fontFamily = '"Be Vietnam Pro", sans-serif';
        formArea.style.fontSize = "20px";
        formArea.style.fontWeight = "600";
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <style>
        {`
      @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300&family=Roboto:wght@300&display=swap');

      .heading-font{
          font-family: 'Be Vietnam Pro', sans-serif;
      }
      
      .paragraph-font{
          /* font-family: 'Inter', sans-serif; */
          font-family: 'Be Vietnam Pro', sans-serif;
      }
      
      /* Custom radio button styles */
      .custom-radio {
          appearance: none;
          display: inline-block;
          width: 16px;
          height: 16px;
          border: 2px solid #ccc;
          border-radius: 50%;
          margin-right: 8px;
          vertical-align: middle;
          outline: none;
          cursor: pointer;
        }
        
        .custom-radio:checked {
          background-color: #FFA900;
          border-color: #FFA900;
        }
        
        .custom-radio:checked:hover {
          background-color: #FFA900;
          border-color: #FFA900;
        }
        
        .custom-radio:checked:focus {
          background-color: #FFA900;
          border-color: #FFA900;
        }
      
        .custom-radio:focus {
          outline: none;
          box-shadow: none;
        }
        
        .custom-radio:hover {
          outline: none;
          box-shadow: 0 0 0 2px #FFA900;
        }
        
        .dropzone {
          border: 2px dashed #ccc;
          margin-top: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: start;
          height: 140px;
          position: relative;
        }
        
        .dropzone.dragging {
          border-color: #ccc;
        }
        
        .placeholder {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: start;
          gap:20px;
          color: black;
        }
        .placeholder:hover{
          cursor: pointer;
        }
        
        .icon {
          font-size: 48px;
        }
        
        .files {
          display: flex;
          flex-wrap: wrap;
        }
        .file-input{
          width: 100%;
        }
        
        .file {
          background-color: #eee;
          color: #333;
          border-radius: 4px;
          padding: 8px 16px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        
        .file:hover {
          background-color: #ddd;
        }
      
       .dropped-images{
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
       }
       
        .dropped-images img {
          max-width: 200px;
          max-height: 200px;
          width: 100px;
          height: 100px;
          border-radius: 8px;
          
        }
      
      .image-container {
          position: relative;
          margin-right: 10px;
          margin-bottom: 10px;
          
        }
        
        .remove-button {
          position: absolute;
          top: -10px;
          right: 0;
          left: 80px;
          width: 30px;
          height: 30px;
          background: white;
          border: 1px solid #FFA900;
          border-radius:30px;
          color: #FFA900;
          align-items: center;
          font-weight: 800;
          font-size: 12px;
          cursor: pointer;
        }
        
        textarea:focus{
          box-shadow: none;
          outline: none;
          border: 1px soild black;
        }
        
        input:focus{
          box-shadow: none;
          outline: none;
          border: 1px soild black;
        }
      
      `}
      </style>
      <div id="question-form" className="max-w-screen-xl mx-auto p-4 md:p-8 py-14 md:py-24">
        {step > 0 && (
          <div className="w-full mb-6">
            <div className="flex justify-between text-md paragraph-font">
              <div className="cursor-pointer" onClick={handlePreviousStep}>
                {t("previous")}
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-center">
          <div className="rounded-lg w-full">
            {step < questions.length ? (
              <div>
                {questions[step].type === "selection" ? (
                  <div>
                    <span className="block text-center">Vrijblijvende Offerte Aanvragen</span>
                    <h1 className="text-3xl mb-10 font-bold text-gray-900 text-center">
                      {questions[step].question}
                    </h1>
                    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 gap-y-1">
                      {questions[step].options.map((option, index) => (
                        <li key={index} className="py-2 paragraph-font text-md">
                          <button
                            className="px-10 py-4 w-full bg-[#d3d3d3] hover:bg-[#ffc863] rounded"
                            onClick={() => handleOptionChange(option)}
                          >
                            {option}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : questions[step].type === "image" ? (
                  <div>
                    <h1 className="text-[#FFA900] paragraph-font font-semibold text-xl mb-4">
                      {t("photosQuestion")}
                    </h1>
                    <div
                      className={`dropzone ${dragging ? "dragging" : ""}`}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onClick={handlePlaceholderClick}
                    >
                      <div className="files"></div>
                      <div className="w-full">
                        <div className="icon"></div>
                        <div className="text-center text-md paragraph-font p-2">
                          {t("photoText")}
                        </div>
                        <input
                          type="file"
                          id="file-input"
                          accept="image/*"
                          multiple
                          onChange={handleInputChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                    <div className="dropped-images">
                      {files.map((file, index) => (
                        <div key={index} className="image-container">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                          />
                          <button
                            className="remove-button flex item-center justify-center text-xs"
                            onClick={() => handleRemove(index)}
                          >
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    <h1 className="text-[#FFA900] paragraph-font font-semibold text-xl mb-4">
                      {t("personalInfo")}
                    </h1>

                    <div>
                      <form onSubmit={handleInfoSubmit}>
                        <div className="flex flex-col md:flex-row gap-6 md:gap-10 w-full mt-6">
                          <div className="w-full">
                            <label className="text-[#FFA900] mr-10 paragraph-font font-semibold text-xl">
                              {t("name")}
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="rounded w-full max-w-[800px] paragraph-font focus:outline-none focus:border-black focus:ring-0"
                              required
                            />
                          </div>
                          <div className="w-full">
                            <label className="text-[#FFA900] mr-10 paragraph-font font-semibold text-xl">
                              {t("email")}
                            </label>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="rounded w-full max-w-[800px] paragraph-font focus:outline-none focus:border-black focus:ring-0"
                              required
                            />
                          </div>
                          <div className="w-full">
                            <label className="text-[#FFA900] mr-10 paragraph-font font-semibold text-xl">
                              {t("phone")}
                            </label>
                            <input
                              type="text"
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                              className="rounded w-full max-w-[800px] paragraph-font focus:outline-none focus:border-black focus:ring-0"
                              required
                            />
                          </div>
                        </div>
                        <div className="flex justify-end flex-col md:flex-row grow-0 mt-10">
                          <button
                            className="mt-4 paragraph-font px-24 py-3 text-white transition bg-[#FFA900] rounded shadow-lg hover:opactiy-800"
                            type="submit"
                          >
                            {t("next")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {step > 0 &&
                  questions[step].type != "selection" &&
                  questions[step].type != "form" && (
                    <div className="flex justify-end flex-col md:flex-row grow-0 mt-10">
                      {step == 3 && (
                        <button
                          className="mt-4 block w-full px-24 py-3 mr-3 paragraph-font bg-white rounded shadow text-yellow-500 sm:w-auto hover:text-yellow-600 active:text-yellow-500 focus:outline-none focus:ring"
                          onClick={handleNextStep}
                        >
                          {t("skip")}
                        </button>
                      )}

                      <button
                        className="mt-4 paragraph-font px-24 py-3 text-white transition bg-[#FFA900] rounded shadow-lg hover:opactiy-800"
                        onClick={handleNextStep}
                      >
                        {t("next")}
                      </button>
                    </div>
                  )}
              </div>
            ) : (
              <div className="w-full">
                <h1 className="text-[#FFA900] paragraph-font font-semibold text-xl mb-4">
                  {t("concernsQuestion")}
                </h1>
                <div>
                  <textarea
                    rows={8}
                    className="w-full paragraph-font outline-none focus:outline-none"
                    value={concernQuestion}
                    onChange={(e) => setConcernQuestion(e.target.value)}
                  />
                </div>
                <div className="flex justify-end flex-col md:flex-row grow-0 mt-10">
                  <button
                    onClick={handleSubmit}
                    className="mt-4 paragraph-font px-24 py-3 text-white transition bg-[#FFA900] rounded shadow-lg hover:opactiy-800"
                  >
                    {t("submit")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
