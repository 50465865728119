import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Adjust the path as needed
import Question from '../components/Questions';
import { createRoot } from "react-dom/client";

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Question />
    </I18nextProvider>
  );
};

const targetDiv = document.getElementById('form');

if (targetDiv) {
  const root = createRoot(targetDiv);
  root.render(<App />);
} else {
  console.error('Could not find the div with the ID "react-form".');
}